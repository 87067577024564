<template>
  <div class="container">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <!-- <div class="dohand">
        <div class="preset">预设</div>
        <div class="amount">金额<input type="text" /></div>
        <div class="btn none">确认</div>
        <div class="btn yes">重置</div>
        <div class="btn2 a">结果走势</div>
      
      </div> -->

      <div class="player listmain type-f3d f3d game-f3d game-f3d-2zh">

        <div class="data">
          <h3>二字</h3>
          <ul class="custom_ul">
            <li :class="ele.selected?'selected':''" v-for="ele in rowsData[0].arr" @click="setCurrentSelected(ele)" :key="ele.ResultID">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>
              <ol class="td_cash">
                <input v-model="ele.money" @click.stop="showOrHideCheck($event, ele)" type="number">
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>

              </ol>
            </li>
            <!-- <li></li> -->
          </ul>
        </div>

      </div>
     
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span class="selected">快选</span>
         
        </div>
        <div style="height:1px"></div>
        <section class="other_row">
          <div class="kx_row" :class="{shang:iIndex<selNumberData1.length-1}" v-for="(item,iIndex) in selNumberData1" :key="item.title">
                    <em class="font_bold">{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel1(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>

                 
                  </div>
        </section>
        <section class="other_row h60">
          <input class="t2" type="checkbox" :checked="condition.bdwhf==2" :value="2" @change="hefenChange">两数合
          
          <div class="kx_row" >
             
              
              
               <span class="dwhefen_num" v-for="(kn,index) in bdwhenfenNumArr" @click="setdwhefenNum1(kn)" :class="{selected:kn.selected}" :key="index+110">{{kn.label}}</span>
              </div>
            </section>
            <section class="other_row">
              <input type="checkbox" @change="hanchange" :checked="condition.hancq=='chu'"  value="chu" class="t2">除
              <input type="checkbox" @change="hanchange" :checked="condition.hancq=='qu'"  value="qu" class="t2" >取
              <!-- <span class="inlineblock" style="width:8px"></span> -->
              二字现 <span class="font_bold">含</span>

              <input  type="number"  @change="qzchange" v-model="condition.haninput" class="w40">
              二字现 <span class="font_bold">复式</span>
              <input type="text" @change="qzchange" v-model="condition.fushiinput" class="w40">
            </section>
            <section class="other_row">
              <input type="checkbox" value="chu" @change="scchange" :checked="condition.sc=='chu'" class="t2">除
              <input type="checkbox" value="qu" @change="scchange" :checked="condition.sc=='qu'" class="t2">取
              <!-- <span class="inlineblock" style="width:8px"></span> -->
              (<span class="font_bold">双重</span>)
        
            </section>
            <section class="other_row">
              <input type="checkbox" @change="xd2change" :checked="condition.xd2=='chu'" class="t2" value="chu">除
              <input type="checkbox" @change="xd2change" :checked="condition.xd2=='qu'" class="t2" value="qu">取
              <!-- <span class="inlineblock" style="width:8px"></span> -->
              (<span class="font_bold">二兄弟</span>)
          
            </section>
            <section class="other_row">
              <input type="checkbox" class="t2" @change="dschange" :checked="condition.ds=='chu'" value="chu">除
              <input type="checkbox" class="t2" @change="dschange" :checked="condition.ds=='qu'" value="qu">取
              <!-- <span class="inlineblock" style="width:4px"></span> -->
              (<span class="font_bold">对数</span>)

              <input type="text" v-model="condition.dsinput1" @change="dsinputchange($event,1)" :maxlength="2" class="w30">
              <input type="text" v-model="condition.dsinput2" @change="dsinputchange($event,2)" :maxlength="2" class="w30">
              <input type="text" v-model="condition.dsinput3" @change="dsinputchange($event,3)" :maxlength="2" class="w30">
              <input type="text" v-model="condition.dsinput4" @change="dsinputchange($event,4)" :maxlength="2" class="w30">
              <input type="text" v-model="condition.dsinput5" @change="dsinputchange($event,5)" :maxlength="2" class="w30">
            </section>
            <section class="other_row">  
              <input type="checkbox" @change="danchange" :checked="condition.dancq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="danchange" :checked="condition.dancq=='qu'" class="t2" value="qu">取
              <!-- <span class="inlineblock" style="width:8px"></span> -->
              (<span class="font_bold">单</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected"  v-for="(item,index) in danxianArr" :key="index+200">
           <span class="green">{{cqLabel[this.condition.dancq]}}   {{danxianlabel}}</span> 
            </section>
            <section class="other_row">
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='qu'" value="qu" class="t2">取
              <!-- <span class="inlineblock" style="width:8px"></span> -->
              (<span class="font_bold">双</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in shuangxianArr" :key="index+300">
           <span class="green">{{cqLabel[this.condition.shuangcq]}}   {{shuangxianlabel}}</span> 
            </section>
            <section class="other_row">
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='chu'" value="chu"  class="t2">除
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='qu'" value="qu" class="t2">取
              <!-- <span class="inlineblock" style="width:8px"></span> -->
              (<span class="font_bold">小</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in xiaoxianArr" :key="index+400">
           <span class="green">{{cqLabel[this.condition.xiaocq]}}   {{xiaoxianlabel}}</span> 
            </section>
           
            <section  class="other_row">
              <input type="checkbox" @change="dachange" :checked="condition.dacq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="dachange" :checked="condition.dacq=='qu'" value="qu" class="t2">取
              <!-- <span class="inlineblock" style="width:8px"></span> -->
              (<span class="font_bold">大</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in daxianArr" :key="index+500">
           <span class="green">{{cqLabel[this.condition.dacq]}}   {{daxianlabel}}</span> 
            </section>


      </div>
      <!-- <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
         
        </div>
        <div class="rc-tab-long">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist">
            <li>
              <span>1-2【龙】</span>
              <b class="blue">10期</b>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import kxmixins from "../mixins/kxmixins";
export default {
  name: "erzixian",
  props: [""],
  mixins: [mixins,kxmixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      nofuzhi:true,
      groupnames:'2z',
      allData:[],
      // 长龙 tab
      changelongActive: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [{ title: "二字", arr: [] }],
      labelArr: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "11",
        "12",
        "13",
        "14",
        '15',
        '16',
        '17',
        '18',
        '19',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38',
        '39',
        '44',
        '45',
        '46',
        '47',
        '48',
        '49',
        '55',
        '56',
        '57',
        '58',
        '59',
        '66',
        '67',
        '68',
        '69',
        '77',
        '78',
        '79',
        '88',
        '89',
        '99',
      ],
      xianzi:2,
      isXian:true,
      selNumberData1: [
        {
          title: "取",
          eng: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "配",
          eng: "pei",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
      ],
      danxianArr:[
        {selected:false,disable:false,},
        {selected:false,disable:false,},  
       ],
       shuangxianArr:[
        {selected:false,disable:false,},
        {selected:false,disable:false,},  
       ],
       xiaoxianArr:[
        {selected:false,disable:false,},
        {selected:false,disable:false,},  
       ],
       daxianArr:[
        {selected:false,disable:false,},
        {selected:false,disable:false,},  
       ],
    };
  },
  watch: {
    resultData:{
     deep:true,
     handler(val){
      this.rowsData.forEach(item=>{
        item.arr.forEach(ele=>{
          let b=val.find(selele=>selele.label==ele.label)
          if(b){
            ele.selected=true
            ele.money = this.amount;
          }else{
            ele.selected = false;
            ele.money = "";
          }
        })
      })
      this.$forceUpdate()
     }
    },
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 二字
       
        if(resAArr.length>0){
          let arr1 = this.filterData(resAArr, 3344, 3398, this.labelArr);
         
       
      
        this.rowsData[0].arr = arr1;
    
  
        this.allData=arr1;
        this.$forceUpdate();
        }
       
      },
    },
  },

  created() {
  },
 mounted(){
  this.nodwhefen=true

 },
  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
    multiplyData(newArrData) {
      let len = newArrData.length;
      // 当数组长度大于等于2时
      if (len >= 2) {
        // 第一个数组的长度
        let len1 = newArrData[0].length;
        // 第二个数组的长度
        let len2 = newArrData[1].length;
        //  申明一个新数组,做数据暂存
        let items = new Array();
        // 申明新数组的索引
        let index = 0;
        // 2层嵌套循环,将组合放到新数组中
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            if (items.length < index + 1) {
              items.push([]);
            }
            if (newArrData[0][i] instanceof Array) {
              items[index] = items[index].concat(
                newArrData[0][i].concat(newArrData[1][j])
              );
            } else {
              items[index] = items[index].concat([
                newArrData[0][i],
                newArrData[1][j],
              ]);
            }
            index++;
          }
        }
        // 将新组合的数组并到原数组中
        let newArr = new Array(len - 1);
        for (let i = 2; i < newArrData.length; i++) {
          newArr[i - 1] = newArrData[i];
        }
        newArr[0] = items;
        // 执行回调
        return this.multiplyData(newArr);
      } else {
        // console.log(newArrData[0]);
        return newArrData[0];
      }
    },
  },
};
</script>
<style  scoped>
.listmain > .data > ul.custom_ul {
  width: 100%;
  height: 286px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}

.listmain .data ul li {
  width: 20%;
  height: 26px;
}

.kx_row.da {
  margin-left: 20px;
  width: 70%;
  height: 100%;
  flex-wrap: wrap;
}

.skin-blue .kx_row span.selected {
  color: #fff;
  background: #2161b3;
}
.skin-brown .kx_row span.selected {
  color: #fff;
  background: #a18361;
}
.skin-red .kx_row span.selected {
  color: #fff;
  background: #c2455c;
}
span.dwhefen_num {
  margin-left: 5px;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #f7f7f7;
  font-size: 13px;
  border: 1px solid rgb(187, 185, 185);
  font-weight: bold;
  border-radius: 50%;
}
.kx_row span {
  margin-left: 4px;
  display: inline-block;
 
  text-align: center;
  background: #f7f7f7;
  font-size: 15px;
  border: 1px solid rgb(187, 185, 185);
  font-weight: bold;
  border-radius: 5px;
}

.kx_row.shang {
  border-bottom: 1px solid lightgray;
}
.kx_row {
  display: flex;
  align-items: center;
  height: 50%;
}
.kx_right {
  flex: 1;
  height: 100%;
}

.kx_item.san {
 min-width: 430px;
 width: 430px;
  border-right: 1px solid lightgray;
}
.kx_item.er {
  flex: 1;
}
.kx_left {
  width: 110px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-right: 1px solid lightgray;
}
.skin-blue .kx_left {
  background: #edf4fe;
}
.skin-brown .kx_left {
  background: #f2ede2;
}
.skin-red .kx_left {
  background: #fff5f5;
}
.kx_item {
  height: 100%;
  display: flex;
}
.kuaixuan_wrap {
  display: flex;
  margin-top: 2px;
  width: 100%;
  height: 110px;
  border: 1px solid lightgray;
}
li.liqx i,
li.w2 i {
  font-size: 20px;
  color: #666;
}
li.w2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.liqx {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listmain > .data > ul.custom_ul_h {
  height: 400px;
}
.listmain .data ul li.h50 {
  height: 40px;
}
.listmain .data ul li.w2 {
  width: 2%;
}
</style>